<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>
import webConfig from "@/config/web.config/index";
import { setmMxpanelUserInfo, setmMxpanelValue } from "@/utils/mixpanel"
import { StatsigClient } from '@statsig/js-client'
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';

export default {
  name: "App",
  mounted() {
    // const mixpanelDeviceId = window.sessionStorage.getItem("mixpanelDeviceId");
    // //录屏
    // const myStatsigClient = new StatsigClient(
    //   webConfig.statsigId,
    //   { userID: mixpanelDeviceId }
    // );
    // console.log(myStatsigClient._user.userID);
    // runStatsigSessionReplay(myStatsigClient);
    // runStatsigAutoCapture(myStatsigClient);
    // await myStatsigClient.initializeAsync();

    // 设置mixpanel用户属性
    let userInfo = {
      user_source: "web",
      user_type: "new",
      web_version: webConfig.mixpanelwebVersion
    }
    setTimeout(() => {
      if (this.$route.query.utm_source) {
        userInfo.utm_source = this.$route.query.utm_source;
      }
      if (this.$route.query.utm_medium) {
        userInfo.utm_medium = this.$route.query.utm_medium
      }
      if (this.$route.query.utm_campaign) {
        userInfo.utm_campaign = this.$route.query.utm_campaign
      }
      setmMxpanelUserInfo(
        userInfo
      )
    }, 1000);
    document.body.addEventListener('touchstart', function () { }); //移动端:active伪类无效的解决方法
    // 阻止双击放大页面
    document.addEventListener('dblclick', function (event) {
      event.preventDefault();
    }, { passive: false });
  },

  methods: {
    beforeunload() {
      window.sessionStorage.removeItem("onBoardingRecordInfo");
      window.sessionStorage.removeItem("paywallCountDownTime")
      window.sessionStorage.removeItem("paywallDiscountCountDownTime")
      this.$store.commit("resetOnBoardingRecordInfo")
    }
  }

};
</script>
